import DigipostIcon from "components/DigipostIcon"
import KivraIcon from "../components/KivraIcon"
import MicrosoftOutlookIcon from "../components/MicrosoftOutlookIcon"
import SftpIcon from "components/SftpIcon"

export const editStates = {
    published: 0,
    draft: 1
}

export const workflowAppType = {
    smartForm: 1,
    workflow: 2,
    filter: 3,
    branch: 4,
    restApi: 5,
    distribution: 6,
    microsoftOutlook: 7,
    editor: 8,
    kivra: 9,
    digipost: 10,
    chron: 11,
    sftp: 12,
    jsonReader: 13,
    csvReader: 14,
    loop: 15,
    webhook: 16,
    viewpoint: 17,
    archive: 18,
    toolbox: 19,
}

export const appConnectionType = {
    microsoftOutlook: 1,
    kivra: 2,
    digipost: 3,
    sftp: 4,
    viewpoint: 5,
}

export const workflowStepType = {
    trigger: 1,
    action: 2
}

export const workflowStates = {
    inactive: 1,
    active: 10
}

export const conditionOperators = {
    and: "and",
    or: "or"
}
export const branchType = {
    branch: 1,
    distribution: 2,
    loop: 3
}

export const appEventIconName = {
    smartFormIcon: "smartform-icon",
    workFlowIcon: "workflow-icon",
    filterIcon: "filter-icon",
    branchIcon: "branch-icon",
    restApiIcon: "rest-api-icon",
    distributionIcon: "distribution-icon",
    outlookIcon: "outlook-icon",
    webEditorIcon: "webeditor-icon",
    kivraIcon: "kivra-icon",
    digipostIcon: "digipost-icon",
    chronIcon: "chron-icon",
    sftpIcon: "sftp-icon",
    jsonIcon: "json-icon",
    csvIcon: "csv-icon",
    loopIcon: "loop-icon",
    webhookIcon: "webhook-icon",
    viewpointIcon: "viewpoint-icon",
    archiveIcon: "archive-icon",
    toolboxIcon: "toolbox-icon",
};

export const httpStatusCodes = {
    'CODE_200': 'OK',
    'CODE_201': 'Created',
    'CODE_202': 'Accepted',
    'CODE_203': 'Non-Authoritative Information',
    'CODE_204': 'No Content',
    'CODE_205': 'Reset Content',
    'CODE_206': 'Partial Content',
    'CODE_300': 'Multiple Choices',
    'CODE_301': 'Moved Permanently',
    'CODE_302': 'Found',
    'CODE_303': 'See Other',
    'CODE_304': 'Not Modified',
    'CODE_305': 'Use Proxy',
    'CODE_307': 'Temporary Redirect',
    'CODE_400': 'Bad Request',
    'CODE_401': 'Unauthorized',
    'CODE_402': 'Payment Required',
    'CODE_403': 'Forbidden',
    'CODE_404': 'Not Found',
    'CODE_405': 'Method Not Allowed',
    'CODE_406': 'Not Acceptable',
    'CODE_407': 'Proxy Authentication Required',
    'CODE_408': 'Request Timeout',
    'CODE_409': 'Conflict',
    'CODE_410': 'Gone',
    'CODE_411': 'Length Required',
    'CODE_412': 'Precondition Failed',
    'CODE_413': 'Request Entity Too Large',
    'CODE_414': 'Request-URI Too Long',
    'CODE_415': 'Unsupported Media Type',
    'CODE_416': 'Requested Range Not Satisfiable',
    'CODE_417': 'Expectation Failed',
    'CODE_500': 'Internal Server Error',
    'CODE_501': 'Not Implemented',
    'CODE_502': 'Bad Gateway',
    'CODE_503': 'Service Unavailable',
    'CODE_504': 'Gateway Timeout',
    'CODE_505': 'HTTP Version Not Supported'
};

export const routePath = {
    microsoftOutlook: '/admin/workflow-apps/microsoft-outlook',
    microsoftOutlookWorkflows: '/admin/workflow-apps/microsoft-outlook/workflows',
    kivra: '/admin/workflow-apps/kivra',
    kivraWorkflows: '/admin/workflow-apps/kivra/workflows',
    digipost: '/admin/workflow-apps/digipost',
    digipostWorkflows: '/admin/workflow-apps/digipost/workflows',
    sftp: '/admin/workflow-apps/sftp',
    sftpWorkflows: '/admin/workflow-apps/sftp/workflows',
};

export const oAuthAppConnections = [
    {
        name: 'Microsoft Outlook',
        value: appConnectionType.microsoftOutlook,
        icon: MicrosoftOutlookIcon,
        href: routePath.microsoftOutlook,
    },
    {
        name: 'Kivra',
        value: appConnectionType.kivra,
        icon: KivraIcon,
        href: routePath.kivra,
    },
    {
        name: 'Digipost',
        value: appConnectionType.digipost,
        icon: DigipostIcon,
        href: routePath.digipost,
    },
    {
        name: 'SFTP',
        value: appConnectionType.sftp,
        icon: SftpIcon,
        href: routePath.sftp,
    },
];

export const oAuthConnectionTestComponentType = {
    testing: 'Testing',
    testSuccessful: 'Test successful',
    testFailed: 'Test failed',
    reconnect: 'reconnect',
    none: 'none',
};

export const kivraEnvironments = [
    { name: 'Sandbox', value: 1 },
    { name: 'Production', value: 2 },
];

export const digipostEnvironments = [
    { name: 'Test', value: 1 },
    { name: 'Production', value: 2 },
];

export const centerPointEnvironments = {
    development: 1,
    test: 5,
    preProd: 8,
    production: 10,
}

export const centerpointEnvironmentNames = {
    [centerPointEnvironments.development]: 'Development',
    [centerPointEnvironments.test]: 'Test',
    [centerPointEnvironments.preProd]: 'Pre Production',
    [centerPointEnvironments.production]: 'Production',
}

export const sftpAuthenticationMethods = [
    { name: 'SSH Password', value: 1 },
    { name: 'SSH Key pair', value: 2 },

];

export const workflowTemplateType = {
    workflowTriggeredByWorkflow: 1,
    kivraSendLetter: 2,
    elementValueChangeAndRestApiPostAndUpdateValue: 3,
};

export const filterConditions = [
    {
        key: "text-contains",
        name: "Text Contains"
    },
    {
        key: "text-matches",
        name: "Text Matches",
    },
    {
        key: "text-not-contains",
        name: "Text Not Contains"
    },
    {
        key: "bool-is-true",
        name: "Boolean is True"
    },
    {
        key: "bool-is-false",
        name: "Boolean Is False"
    }
];

export const viewpointConnectionState = {
    active: 1,
    inactive: 2,
};

export const editorEnvironments = {
    development: 0,
    test: 1,
    test1: 2,
    test2: 3,
    test3: 4,
    test4: 5,
    test5: 6,
    integrationTest: 7,
    acceptanceTest: 8,
    production: 9,
};

export const editorEnvironmentNames = {
    [editorEnvironments.development]: 'Development',
    [editorEnvironments.test]: "Test",
    [editorEnvironments.test1]: "Test 1",
    [editorEnvironments.test2]: "Test 2",
    [editorEnvironments.test3]: "Test 3",
    [editorEnvironments.test4]: "Test 4",
    [editorEnvironments.test5]: "Test 5",
    [editorEnvironments.integrationTest]: "IntegrationTest",
    [editorEnvironments.acceptanceTest]: "AcceptanceTest",
    [editorEnvironments.production]: "Production",
};

export const viewpointProperties = {
    schema: "Schema",
    requiredColumns: "ViewpointRequiredColumns",
    retentionPolicy: "RetentionPolicy",
    metadataPrefix: "Metadata-",
    environmentId: "Metadata-EnvironmentId",
    environmentGroupId: "Metadata-EnvironmentGroupId",
};

export const viewpointColumnType = {
    array: "Array",
    boolean: "Boolean",
    string: "String",
};

export const fileUploadType = {
    uploadAndDragAndDrop: 0,
    uploadButton: 1,
}

export const stepProcessType = {
    background: 5,
    userInTheLoop: 99,
}